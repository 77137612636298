<template>
  <div>
    <theme0 v-if="kindTheme===0" />
    <theme1 v-else-if="kindTheme===1" />
  </div>
</template>

<script>
import mixinTheme from '@/components/mixins/mixinTheme';

export default {
  name: 'Home',
  mixins: [mixinTheme],
  components: {
    Theme0: () => import('./theme0'),
    Theme1: () => import('./theme1'),
  },
};
</script>
